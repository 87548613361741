var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    class: ['toast', _vm.type],
    on: {
      "click": _vm.closeToast
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c('div', {
    staticClass: "ml-3"
  }, [_c('svg', {
    staticClass: "w-3 h-3",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 14 14"
    },
    on: {
      "click": _vm.closeToast
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
    }
  })])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="bg-white p-4  my-4 rounded-lg shadow-sm">
      <Toast />
      <label>Content Recommendation</label>
      <h4 class="text-2xl">All Content Recommendation</h4>
    </div>
    <div class="bg-white content-recomendation block rounded-lg shadow-sm">

      <div class="flex w-1/2 p-10">
        <span
          class="inline-flex text-xs border-l border-t border-b items-center px-3 text-gray-900  bg-gray-200 rounded-tl-md rounded-bl-md border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
          RECOMMENDATION TITLE
        </span>
        <input placeholder="Search" 
        type="text" 
        v-model="query_search"
        @input="setQuerySearch" 
        @keyup.enter="getListRecomendasi"
        id="website-admin"
          class="rounded-br-md rounded-tr-md border-r border-t border-b  rounded-e-lg bg-gray-50  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>


      <div class="relative">
        <div>
          <table class="w-full text-sm text-gray-500 text-left rtl:text-right bg-white dark:text-gray-400">
          <thead class="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr class="bg-gray-50">
              <th scope="col" class="px-6 py-3">Recommendation Title</th>
              <th scope="col" class="px-6 py-3">Description</th>
              <th scope="col" class="px-6 py-3">Content Amount</th>
              <th scope="col" class="px-6 py-3">
                <div class="flex items-center cursor-pointer" @click="sortBy('modified')">
                  Last Modified
                  <span>
                    <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3">
                <div class="flex items-center cursor-pointer" @click="sortBy('created')">
                  Created Date
                  <span>
                    <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3"></th>
            </tr>
          </thead>
        
          <tbody>
            <tr v-if="isLoading">
              <div role="status" class="absolute bg-white w- -translate-x-1/2 -translate-y-1/2 top-40  left-1/2">
                <svg aria-hidden="true" class="w-8 h-8 text-blue-500 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
             </div>
            </tr>
            <tr v-else-if="items.length === 0">
                <td colspan="6" class="px-6 py-4 text-center text-gray-500">No data available</td>
            </tr>
            <tr v-else v-for="(data, index) in sortedItems" :key="index"
              :class="{ 'active-row bg-gray-200': selectedIndex === index, 'bg-white': selectedIndex !== index, 'border-b': true }"
              class="border-b focus:bg-gray-500">
              <th scope="row" class="px-6 py-3 font-medium text-gray-500 whitespace-nowrap dark:text-white">
                {{ data.title }}
              </th>
              
       
              <p class="w-96 mt-3 truncate-2-lines">
                        {{ data?.description || '-' }}
              </p>
              <td class="px-6 py-3">
                <div class="flex"> {{ data?.content_count }} Contents</div>
              </td>
              <td class="px-6 py-3">{{ formatDate(data?.modified)}}</td>
              <td class="px-6 py-3">{{ formatDate(data?.created) }}</td>
            
            
              <!-- <td class="px-6 py-3">{{ product.price }}</td> -->
              <td class="px-6 py-3 text-right outline-none relative">
                <div class="navbar-right relative">
                  <button @click="toggleDropdown(index)" id="dropdownMenuIconHorizontalButton"
                    class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900" type="button">
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                      viewBox="0 0 16 3">
                      <path
                        d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>
                  </button>
                  <div v-if="selectedIndex === index" @click="closeDropdown" tabindex="-1"
                    class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></div>
                  <div v-if="selectedIndex === index"
                    class="absolute z-10 top-auto right-2 w-48 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl">
                    <ul class="px-2 mb-0 text-sm text-gray-500 dark:text-gray-200"
                      aria-labelledby="dropdownMenuIconHorizontalButton">
                      <li>
                        <router-link class="text-gray-500 w-full block list-none" :to="{ path: `/content-recommedation/edit/${data.id}`}">
                        <div
                          class="flex w-full items-end justify-start px-3 py-2 cursor-pointer  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-500 text-left ">
                           <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z" clip-rule="evenodd"/>
                                <path fill-rule="evenodd" d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z" clip-rule="evenodd"/>
                              </svg>
                            <span class="ml-2">Edit</span>
                            </div>
                      </router-link>
                      </li>
                      <li>
                          <router-link class="text-gray-500 w-full block list-none" :to="{ path: `/content-recommedation/detail/${data.id}`}">
                        <div
                          class="flex w-full items-end justify-start px-3 py-2  hover:bg-gray-100 cursor-pointer text-gray-500 text-left ">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                                <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                            <span class="ml-2">View</span>
                            </div>
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
    <div class="flex bg-white p-4 mb-5 rounded-bl-lg rounded-br-lg">
      Showing 1-{{items.length}} of {{items.length}}
    </div>
  </div>
</template>

<script>
import 'flowbite';
import { mapActions, mapState, mapMutations } from "vuex";
import { format } from 'date-fns';
import Toast from './component/Toast.vue';
export default {
  components: {
    // FbButton,
    Toast
  },
  name: 'RecommendationHomepage',
  data() {
    return {
      selectedIndex: null,
      query_search: '',
      toastVisible: false,
      sortKey: 'created',
      sortOrder: 'asc',
      toastMessage: '',
      toastType: 'info',
      debounce: null,
    };

  },
  watch: {
    successMessage: function () {
            if (!this.successMessage) return;
            this.alertMsg(this.successMessage, 'success');
        },
        errorMessage: function () {
            if (!this.errorMessage) return;
            this.alertMsg(this.errorMessage, 'error');
        },
  },
  computed: {
  ...mapState({
    items: (state) => state.content.items,
    successMessage: (state) => state.content.successMessage,
    alertMessage: (state) => state.content.successMessage,
    alertType: (state) => state.content.alertType,
    errorMessage: (state) => state.content.errorMessage,
    isLoading: (state) => state.content.isLoading,
  }),
  sortedItems() {
    return [...this.items].sort((a, b) => {
      let modifier = this.sortOrder === 'asc' ? 1 : -1;
      if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
      if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
      return 0;
    });
  },
},
  created() {
    this.getListRecomendasi()
  },
  mounted() {
    this.getListRecomendasi()
    document.addEventListener('mousedown', this.handleClickOutside);
   
  },

  methods: {
    ...mapActions("content", ["listRecomendasi"]),
    ...mapMutations('content', ['']),
    toggleDropdown(index) {
      this.selectedIndex = this.selectedIndex === index ? null : index;
    },
    closeDropdown() {
      this.selectedIndex = null;
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    },
    formatDate(date) {
      if (!date) return '';
      return format(new Date(date), 'dd MMM yyyy, HH:mm:ss');
    },
    alertMsg(message, type) {
      this.toastMessage = message;
            this.toastType = type;
            this.toastVisible = true;

            setTimeout(() => {
                this.toastVisible = false;
                window.location.reload()
            }, 2000);
        },
    getListRecomendasi() {
      let payload = {
        q:this.query_search ? this.query_search : '',
      };
      this.listRecomendasi(payload);
    },

    setQuerySearch(event) {
      this.query_search = event.target.value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getListRecomendasi();
        clearTimeout(this.debounce);
      }, 1000);
    },
  }
};
</script>

<style>
.content-recomendation {
  min-height: 800px;
}
.truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 3em;
  }
button {
  outline: none !important;
}
</style>

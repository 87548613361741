<template>
  <div v-if="visible" :class="['toast', type]" @click="closeToast">

    <div class="flex justify-between items-center">
      <div> {{ message }} </div>
      <div class="ml-3">
        <svg @click="closeToast" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
//import { EventBus } from '../../../../src/event-bus';

export default {
  data() {
    return {
      message: '',
      type: '',
      visible: false,
    };
  },
  created() {
   // EventBus.$on('show-toast', this.showToast);
  },
  methods: {
    // showToast({ message, type = 'success' }) {
    //   this.message = message;
    //   this.type = type;
    //   this.visible = true;
    //   setTimeout(() => {
    //     this.visible = false;
    //   }, 3000);
    // },
    // closeToast() {
    //   this.visible = false;
    // },
  },
};
</script>

<style>
.toast {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translate(-50px, 50%);
  padding: 13px 20px;
  border-radius: 5px;
  color: white;
  background-color: #374151;
  opacity: 1;
  z-index: 1000;


}

.toast.success {
  background-color: #374151;
}

.toast.error {
  background-color: #f44336;
}

.toast.warning {
  background-color: #ff9800;
}
</style>
